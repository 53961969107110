import { useState, useEffect } from "react";
import { CostModel } from "../../../models/TransportModel";
import { v4 as uuidv4 } from 'uuid';
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Divider, Heading, Button, SimpleGrid, HStack, FormControl, FormLabel, Grid, GridItem } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { ClearMoney, FormateMoney, FormateMoneyFloat } from "../../../utils/utils";
import { OptionsProps, Select2 } from "../../../components/Form/Select";

interface TransportCostsProps {
    useFormReturn: UseFormReturn<FieldValues, any>
    transportCosts?: CostModel[]
}

export function TransportsCosts({ useFormReturn, transportCosts }: TransportCostsProps) {

    const { register, getValues, formState, reset, setValue } = useFormReturn
    const { errors } = formState;

    const [costFields, setCostFields] = useState<CostModel[]>([])

    const handleChangeCost = <T extends keyof CostModel>(uuid: string, field: T, value: CostModel[T]) => {
        const newCostFields = costFields.map(cost => {
            if (cost.uuid === uuid) {
                cost[field] = value;
            }
            console.log(cost)
            return cost;
        });
        setCostFields(newCostFields);
    };

    const handleAddCost = () => {
        setCostFields([...costFields, { uuid: uuidv4() } as CostModel]);
    }

    let handleRemoveCost = (i: number) => {
        let newCostFields = [...costFields];
        newCostFields.splice(i, 1);
        setCostFields(newCostFields)

        reset({ ...getValues(), costs: [] })
        setupCostFields(newCostFields)
    }

    useEffect(() => {
        if (costFields.length == 0 && transportCosts != undefined) {
            setCostFields(transportCosts)
            setupCostFields(transportCosts)            
        }
    }, [transportCosts])

    function setupCostFields(contractCosts: CostModel[]) {
        reset({ ...getValues(), costs: [] })
        contractCosts.map((field, index) => {
            if (field.description != undefined)
                setValue(`costs.${index}.description`, field.description);
            if (field.amount != undefined)
                setValue(`costs.${index}.amount`, FormateMoneyFloat(field.amount.toString() ?? ""));
        })
    }

    const OptionsKind: OptionsProps[] = [
        { label: "FIXO", value: "FIXO" },
        { label: "POR SACA", value: "SACA" },
        { label: "POR TONELADA", value: "TONELADA" },
    ]

    return (
        <>
            <Divider />
            <Heading size={"md"} fontWeight={"normal"} flex={"1"}>
                Custos adicionais do carregamento
                <Button
                    onClick={handleAddCost}
                    colorScheme={"green"} size={"sm"} ml={4}>
                    Adicionar
                </Button>
            </Heading>

            {
                costFields.map((field, index) => (
                    <>
                        <Grid w={"100%"} templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }} gap={{ base: 0, md: 4 }} key={index}>
                            <GridItem colSpan={6}>
                                <Input
                                    label={`Descrição do custo`}
                                    error={errors.costs?.[index]?.description}
                                    onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                                        handleChangeCost(field.uuid, "description", e.currentTarget.value)
                                    }}
                                    defaultValue={field.description}
                                    {...register(`costs.${index}.description`)}
                                />
                            </GridItem>


                            <GridItem colSpan={2}>
                                <Select2
                                    label="Tipo"
                                    options={OptionsKind}
                                    error={errors.costs?.[index]?.kind}
                                    name={`costs.${index}.kind`}
                                    onChangeSelect={(value) => {
                                        handleChangeCost(field.uuid, "kind", value)
                                    }}
                                    useFormReturn={useFormReturn}
                                    selectedValue={field.kind}
                                />
                            </GridItem>
                            <GridItem colSpan={2}>
                                <Input
                                    label={`Valor`}
                                    error={errors.costs?.[index]?.amount}
                                    defaultValue={field?.amount != undefined ? FormateMoneyFloat(field.amount.toString()) : ""}
                                    onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                                        const value = FormateMoney(e.currentTarget.value)
                                        e.currentTarget.value = value
                                        handleChangeCost(field.uuid, "amount", parseFloat(ClearMoney(value)))
                                    }}
                                    {...register(`costs.${index}.amount`)}
                                />
                            </GridItem>
                            <GridItem colSpan={2}>
                                <FormControl>
                                    <FormLabel>-</FormLabel>
                                    <Button
                                        onClick={() => { handleRemoveCost(index) }}
                                        colorScheme={"red"} size={"lg"}
                                        w={"100%"}>
                                        Remover
                                    </Button>
                                </FormControl>
                            </GridItem>
                        </Grid>
                    </>
                ))
            }
            <Divider />
        </>
    )
}