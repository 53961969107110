import { useState, useEffect } from "react";
import { TransportInvoiceModel } from "../../../models/TransportModel";
import { v4 as uuidv4 } from 'uuid';
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Divider, Heading, Button, SimpleGrid, FormControl, FormLabel } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { ClearMoney, ClearQuilo, FormateMoney, FormateMoneyFloat, FormatePercentage, FormateQuilo } from "../../../utils/utils";
import { InputFile } from "../../../components/Form/InputFile";
import { OptionsProps, Select2 } from "../../../components/Form/Select";


interface TransportInvoicesProps {
    useFormReturn: UseFormReturn<FieldValues, any>
    transportInvoices?: TransportInvoiceModel[]
    optionsCompanies: OptionsProps[]
}

export function TransportsInvoices({ useFormReturn, transportInvoices, optionsCompanies }: TransportInvoicesProps) {

    const { register, getValues, formState, reset, setValue } = useFormReturn
    const { errors } = formState;

    const [invoiceFields, setInvoiceFields] = useState<TransportInvoiceModel[]>([])

    const handleChangeInvoice = <T extends keyof TransportInvoiceModel>(uuid: string, field: T, value: TransportInvoiceModel[T]) => {
        const newFields = invoiceFields.map(invoice => {
            if (invoice.uuid === uuid) {
                invoice[field] = value;
            }
            return invoice;
        });
        setInvoiceFields(newFields);
    };

    const handleAddInvoice = () => {
        setInvoiceFields([...invoiceFields, { uuid: uuidv4() } as TransportInvoiceModel])
    }

    let handleRemoveInvoice = (i: number) => {
        let newInvoiceFields = [...invoiceFields];
        newInvoiceFields.splice(i, 1);
        setInvoiceFields(newInvoiceFields)
        setupInvoiceFields(newInvoiceFields)
    }

    useEffect(() => {
        if (invoiceFields.length == 0 && transportInvoices != undefined) {
            setInvoiceFields(transportInvoices)
            setupInvoiceFields(transportInvoices)
        }
    }, [transportInvoices])

    function setupInvoiceFields(transportInvoices: TransportInvoiceModel[]) {
        reset({ ...getValues(), transport_invoices: [] })
        transportInvoices.map((field, index) => {
            if (field.identifier != undefined)
                setValue(`transport_invoices.${index}.identifier`, field.identifier);
            if (field.total_amount != undefined)
                setValue(`transport_invoices.${index}.total_amount`, FormateMoneyFloat(field.total_amount.toString() ?? ""));
            if (field.total_weight != undefined)
                setValue(`transport_invoices.${index}.total_weight`, FormateQuilo(field.total_weight.toString() ?? ""));
            if (field.total_tax != undefined)
                setValue(`transport_invoices.${index}.total_tax`, FormateMoneyFloat(field.total_tax.toString() ?? ""));
            if (field.rate_tax != undefined)
                setValue(`transport_invoices.${index}.rate_tax`, field.rate_tax.toString() );            
        })
    }

    return (
        <>
            <Divider />
            <Heading size={"md"} fontWeight={"normal"} flex={"1"} mb={10}>
                Notas fiscais utilizadas no carregamento
                <Button
                    onClick={handleAddInvoice}
                    colorScheme={"green"} size={"sm"} ml={4}>
                    Adicionar
                </Button>
            </Heading>

            {
                invoiceFields.map((field, index) => (
                    <>
                        <Divider />
                        <div key={index}>
                            <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"} mb={4}>
                                <Select2
                                    label="Firma emissora da NF"
                                    options={(optionsCompanies.length > 0) ? optionsCompanies : [{ label: "Carregando...", value: "-" }]}
                                    error={errors.transport_invoices?.[index]?.company_uuid}
                                    name={`transport_invoices.${index}.company_uuid`}
                                    onChangeSelect={(companyUUID) => { handleChangeInvoice(field.uuid, "company_uuid", companyUUID) }}
                                    useFormReturn={useFormReturn}
                                    selectedValue={field.company_uuid}
                                />                                
                                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"} mb={4}>
                                <Input
                                    label={`Nº NF`}
                                    error={errors.transport_invoices?.[index]?.identifier}
                                    onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                                        handleChangeInvoice(field.uuid, "identifier", e.currentTarget.value)
                                    }}
                                    defaultValue={field.identifier}
                                    {...register(`transport_invoices.${index}.identifier`)} />
                                <Input
                                    label={`Qtde. Kg`}
                                    error={errors.transport_invoices?.[index]?.total_weight}
                                    defaultValue={field?.total_weight != undefined ? FormateQuilo(field.total_weight.toString()) : ""}
                                    onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                                        const value = FormateQuilo(e.currentTarget.value)
                                        e.currentTarget.value = value
                                        handleChangeInvoice(field.uuid, "total_weight", parseFloat(ClearQuilo(value)))
                                    }}
                                    {...register(`transport_invoices.${index}.total_weight`)} />                                       
                            </SimpleGrid>
                            </SimpleGrid>
                            <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"} mb={4}>       
                                <Input
                                    label={`Valor NF`}
                                    error={errors.transport_invoices?.[index]?.total_amount}
                                    defaultValue={field?.total_amount != undefined ? FormateMoneyFloat(field.total_amount.toString()) : ""}
                                    onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                                        const value = FormateMoney(e.currentTarget.value)
                                        e.currentTarget.value = value
                                        handleChangeInvoice(field.uuid, "total_amount", parseFloat(ClearMoney(value)))

                                        const rate_tax = parseFloat(getValues(`transport_invoices.${index}.rate_tax`)) ?? 0
                                        const total_tax = (rate_tax * parseFloat(ClearMoney(value))) / 100
                                        if (total_tax > 0) {
                                            handleChangeInvoice(field.uuid, "total_tax", total_tax)
                                            setValue(`transport_invoices.${index}.total_tax`, FormateMoneyFloat(total_tax.toString()))    
                                        }else{
                                            setValue(`transport_invoices.${index}.total_tax`, "")    
                                        }
                                    }}
                                    {...register(`transport_invoices.${index}.total_amount`)} />                         
                                <Input
                                    label={`% Aliquota`}
                                    error={errors.rate_tax?.[index]?.rate_tax}
                                    defaultValue={field?.rate_tax != undefined ? FormateMoneyFloat(field.rate_tax.toString()) : ""}
                                    onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                                        const value = FormatePercentage(e.currentTarget.value)
                                        e.currentTarget.value = value
                                        handleChangeInvoice(field.uuid, "rate_tax", parseFloat(value))

                                        const total_amount = parseFloat(ClearMoney(getValues(`transport_invoices.${index}.total_amount`))) ?? 0
                                        const percentage = parseFloat(value)
                                        const total_tax = (percentage * total_amount) / 100

                                        if (total_tax > 0) {
                                            handleChangeInvoice(field.uuid, "total_tax", total_tax)
                                            setValue(`transport_invoices.${index}.total_tax`, FormateMoneyFloat(total_tax.toString()))    
                                        }else{
                                            setValue(`transport_invoices.${index}.total_tax`, "")    
                                        }                                       
                                    }}
                                    {...register(`transport_invoices.${index}.rate_tax`)} />
                                <Input
                                    label={`R$ Imposto`}
                                    isDisabled={true}
                                    error={errors.transport_invoices?.[index]?.total_tax}
                                    defaultValue={field?.total_tax != undefined ? FormateMoneyFloat(field.total_tax.toString()) : ""}                                    
                                    {...register(`transport_invoices.${index}.total_tax`)}
                                     />  
                                <FormControl>
                                    <FormLabel>Remover?</FormLabel>
                                    <Button
                                        onClick={() => { handleRemoveInvoice(index) }}
                                        colorScheme={"red"} size={"lg"}
                                        w={"100%"}>
                                        Remover
                                    </Button>
                                </FormControl>
                            </SimpleGrid>                            
                        </div>
                    </>
                ))
            }
            <Divider />
        </>
    )
}