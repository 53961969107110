import { useState, useEffect } from "react";
import { TransportContractModel } from "../../../models/TransportModel";
import { v4 as uuidv4 } from 'uuid';
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Divider, Heading, Button, SimpleGrid, HStack, FormControl, FormLabel, Grid, GridItem } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { ClearQuilo, FormateQuilo } from "../../../utils/utils";
import { OptionsProps, Select2 } from "../../../components/Form/Select";

interface TransportContractsProps {
    useFormReturn: UseFormReturn<FieldValues, any>
    optionsContracts: OptionsProps[]
    transportContracts?: TransportContractModel[]
}

export function TransportsContracts({ useFormReturn, optionsContracts, transportContracts }: TransportContractsProps) {

    if (optionsContracts.length == 0) return (<></>)

    const { register, getValues, formState, reset, setValue } = useFormReturn
    const { errors } = formState;

    const [contractFields, setContractFields] = useState<TransportContractModel[]>([])

    const handleChangeContract = <T extends keyof TransportContractModel>(uuid: string, field: T, value: TransportContractModel[T]) => {
        const newContractFields = contractFields.map(contract => {
            if (contract.uuid === uuid) {
                contract[field] = value;
            }
            return contract;
        });
        setContractFields(newContractFields);
    };

    const handleAddContract = () => {
        setContractFields([...contractFields, { uuid: uuidv4() } as TransportContractModel])
    }

    let handleRemoveContract = (i: number) => {
        let newContractFields = [...contractFields];
        newContractFields.splice(i, 1);
        setContractFields(newContractFields)
        setupContractFields(newContractFields)
    }

    useEffect(() => {
        if (contractFields.length == 0 && transportContracts != undefined) {
            setContractFields(transportContracts)
            setupContractFields(transportContracts)
        }
    }, [transportContracts])

    function setupContractFields(contractFields: TransportContractModel[]) {
        reset({ ...getValues(), transport_contracts: [] })
        contractFields.map((field, index) => {
            if (field.total_weight != undefined) setValue(`transport_contracts.${index}.total_weight`, FormateQuilo(field.total_weight.toString() ?? ""));
            if (field.total_weight != undefined) setValue(`transport_contracts.${index}.moisture_discount`, FormateQuilo(field.moisture_discount?.toString() ?? ""));
            if (field.total_weight != undefined) setValue(`transport_contracts.${index}.impurity_discount`, FormateQuilo(field.impurity_discount?.toString() ?? ""));
            if (field.total_weight != undefined) setValue(`transport_contracts.${index}.outhers_discount`, FormateQuilo(field.outhers_discount?.toString() ?? ""));
            if (field.total_weight != undefined) setValue(`transport_contracts.${index}.liquid_weight`, FormateQuilo(field.liquid_weight?.toString() ?? ""));
            if (field.contract_uuid != undefined) setValue(`transport_contracts.${index}.contract_uuid`, field.contract_uuid);
        })
    }

    const handleLiquidWeight = (uuid: string, index: number) => {
        let contract = contractFields.find(contract => contract.uuid === uuid)
        if (contract != undefined) {
            let total_weight = parseFloat(ClearQuilo(contract.total_weight?.toString() ?? "0"))
            let moisture_discount = parseFloat(ClearQuilo(contract.moisture_discount?.toString() ?? "0"))
            let impurity_discount = parseFloat(ClearQuilo(contract.impurity_discount?.toString() ?? "0"))
            let outhers_discount = parseFloat(ClearQuilo(contract.outhers_discount?.toString() ?? "0"))
            let liquid_weight = total_weight - (moisture_discount + impurity_discount + outhers_discount)            
            console.log("liquid_weight", liquid_weight)
            handleChangeContract(uuid, "liquid_weight", liquid_weight)
            setValue(`transport_contracts.${index}.liquid_weight`, FormateQuilo(liquid_weight.toString() ?? ""));
        }        
    }

    return (
        <>
            <Divider />
            <Heading size={"md"} fontWeight={"normal"} flex={"1"}>
                Contratos vinculados ao carregamento
                <Button
                    onClick={handleAddContract}
                    colorScheme={"green"} size={"sm"} ml={4}>
                    Adicionar
                </Button>
            </Heading>

            {
                contractFields.map((field, index) => (
                    <>
                        <Divider />
                        <Grid w={"100%"} templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }} gap={{ base: 0, md: 4 }} key={index}>
                            <GridItem colSpan={8}>
                                <Select2 gridColumn={5}
                                    label={`Contrato`}
                                    options={(optionsContracts.length > 0) ? optionsContracts : [{ label: "Carregando...", value: "-" }]}
                                    error={errors.transport_contracts?.[index]?.contract_uuid}
                                    useFormReturn={useFormReturn}
                                    name={`transport_contracts.${index}.contract_uuid`}
                                    selectedValue={field.contract_uuid}
                                    onChangeSelect={(contractUUID) => { handleChangeContract(field.uuid, "contract_uuid", contractUUID) }}
                                />
                            </GridItem>

                            <GridItem colSpan={2} >
                                <Input
                                    label={`Peso bruto`}
                                    error={errors.transport_contracts?.[index]?.total_weight}
                                    onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                                        const value = FormateQuilo(e.currentTarget.value)
                                        e.currentTarget.value = value;
                                        handleChangeContract(field.uuid, "total_weight", parseFloat(ClearQuilo(value)))
                                        handleLiquidWeight(field.uuid, index)
                                    }}
                                    defaultValue={FormateQuilo(field.total_weight?.toString() ?? "")}
                                    {...register(`transport_contracts.${index}.total_weight`)} />
                            </GridItem>

                            <GridItem colSpan={2} >
                                <HStack spacing={8}>
                                    <FormControl>
                                        <FormLabel> - </FormLabel>
                                        <Button
                                            onClick={() => { handleRemoveContract(index) }}
                                            colorScheme={"red"} size={"lg"}
                                            w={"100%"}>
                                            Remover
                                        </Button>
                                    </FormControl>
                                </HStack>
                            </GridItem>
                        </Grid>

                        <Grid w={"100%"} templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }} gap={{ base: 0, md: 4 }} key={index}>

                            <GridItem colSpan={10}>
                                <Grid w={"100%"} templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }} gap={{ base: 0, md: 4 }} key={index}>

                                    <GridItem colSpan={2}>
                                        <Input
                                            label={`% Umi.`}
                                            error={errors.transport_contracts?.[index]?.moisture}
                                            onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                                                handleChangeContract(field.uuid, "moisture", e.currentTarget.value)
                                            }}
                                            defaultValue={field.moisture?.toString()}
                                            {...register(`transport_contracts.${index}.moisture`)} />
                                    </GridItem>
                                    <GridItem colSpan={2}>
                                        <Input
                                            label={`Kg Umi.`}
                                            error={errors.transport_contracts?.[index]?.moisture_discount}
                                            onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                                                const value = FormateQuilo(e.currentTarget.value)
                                                e.currentTarget.value = value;
                                                handleChangeContract(field.uuid, "moisture_discount", parseFloat(ClearQuilo(value)))
                                                handleLiquidWeight(field.uuid, index)
                                            }}
                                            defaultValue={FormateQuilo(field.moisture_discount?.toString() ?? "")}
                                            {...register(`transport_contracts.${index}.moisture_discount`)}
                                        />
                                    </GridItem>

                                    <GridItem colSpan={2}>
                                        <Input
                                            label={`% Imp.`}
                                            error={errors.transport_contracts?.[index]?.impurity}
                                            onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                                                handleChangeContract(field.uuid, "impurity", e.currentTarget.value)
                                            }}
                                            defaultValue={field.impurity?.toString()}
                                            {...register(`transport_contracts.${index}.impurity`)} />
                                    </GridItem>
                                    <GridItem colSpan={2}>
                                        <Input
                                            label={`Kg Imp.`}
                                            error={errors.transport_contracts?.[index]?.impurity_discount}
                                            onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                                                const value = FormateQuilo(e.currentTarget.value)
                                                e.currentTarget.value = value;
                                                handleChangeContract(field.uuid, "impurity_discount", parseFloat(ClearQuilo(value)))
                                                handleLiquidWeight(field.uuid, index)
                                            }}
                                            defaultValue={FormateQuilo(field.impurity_discount?.toString() ?? "")}
                                            {...register(`transport_contracts.${index}.impurity_discount`)}
                                        />
                                    </GridItem>

                                    <GridItem colSpan={2}>
                                        <Input
                                            label={`% Out.`}
                                            error={errors.transport_contracts?.[index]?.outhers}
                                            onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                                                handleChangeContract(field.uuid, "outhers", e.currentTarget.value)
                                            }}
                                            defaultValue={field.outhers?.toString()}
                                            {...register(`transport_contracts.${index}.outhers`)} />
                                    </GridItem>
                                    <GridItem colSpan={2}>
                                        <Input
                                            label={`Kg Out.`}
                                            error={errors.transport_contracts?.[index]?.outhers_discount}
                                            onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                                                const value = FormateQuilo(e.currentTarget.value)
                                                e.currentTarget.value = value;
                                                handleChangeContract(field.uuid, "outhers_discount", parseFloat(ClearQuilo(value)))
                                                handleLiquidWeight(field.uuid, index)
                                            }}
                                            defaultValue={FormateQuilo(field.outhers_discount?.toString() ?? "")}
                                            {...register(`transport_contracts.${index}.outhers_discount`)}
                                        />
                                    </GridItem>

                                </Grid>
                            </GridItem>

                            <GridItem colSpan={2} >
                                <Input
                                    label={`Peso liquido`}
                                    isDisabled={true}
                                    error={errors.transport_contracts?.[index]?.liquid_weight}
                                    onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                                        const value = FormateQuilo(e.currentTarget.value)
                                        e.currentTarget.value = value;
                                        handleChangeContract(field.uuid, "liquid_weight", parseFloat(ClearQuilo(value)))
                                    }}
                                    defaultValue={FormateQuilo(field.liquid_weight?.toString() ?? "")}
                                    {...register(`transport_contracts.${index}.liquid_weight`)} />
                            </GridItem>
                        </Grid>
                    </>
                ))
            }
        </>
    )
}