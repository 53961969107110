import { Flex, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DefaultBox } from "../../components/DefaultBox/DefaultBox";
import { ContainerHeader } from "../../components/ContainerHeader/ContainerHeader";
import { TransportModel } from "../../models/TransportModel";
import { ClearMoney, ClearQuilo, FormToJson, Toast } from "../../utils/utils";
import { useEffect, useState } from "react";
import { DriversNavigator } from "../../components/Form/Navigator/DriversNavigator";
import { showTransport } from "../../services/transports/showTransport";
import { updateTransport } from "../../services/transports/updateTransport";
import { TransportsNavigator } from "../../components/Form/Navigator/TransportsNavigator";
import { ContractsNavigator } from "../../components/Form/Navigator/ContractsNavigator";
import { Form } from "../transports/components/Form";
import { da } from "date-fns/locale";

const formShema = yup.object().shape({
  freight_cost: yup.string().required("Obrigatório"),
  total_quantity: yup.string().required("Obrigatório"),
});

export function Edit() {
  const { uuid, parent_uuid, parent } = useParams();
  const navigate = useNavigate();
  const useFormReturn = useForm({ resolver: yupResolver(formShema) });
  const { setError, handleSubmit } = useFormReturn

  const [transportData, setTransportData] = useState<TransportModel>();
  const getTransportData = async () => {
    const response = await showTransport(uuid!);
    if (response?.httpStatus != 200 && response?.httpStatus != 201) {
      navigate(parent != undefined ? "/transports/" + parent + "/" + parent_uuid : "/transports-general");
      return;
    }
    if (response?.data) {
      setTransportData(response.data)
    }
  }
  useEffect(() => {
    getTransportData();
  }, [])

  const handleFormSubmit: SubmitHandler<FieldValues | TransportModel> = async (values) => {
    const data = JSON.parse(FormToJson(values)) as TransportModel
    data.uuid = uuid!
    if (data.lost_cost != undefined) data.lost_cost = parseFloat(ClearMoney(data.lost_cost.toString()))
    if (data.payment != undefined) data.payment = parseFloat(ClearMoney(data.payment.toString()))    
    if (data.freight_cost != undefined) data.freight_cost = parseFloat(ClearMoney(data.freight_cost.toString()))
    if (data.lost_quantity != undefined) data.lost_quantity = parseFloat(ClearQuilo(data.lost_quantity.toString()))
    if (data.impurity_discount != undefined) data.impurity_discount = parseFloat(ClearQuilo(data.impurity_discount.toString()))
    if (data.moisture_discont != undefined) data.moisture_discont = parseFloat(ClearQuilo(data.moisture_discont.toString()))
    data.total_quantity = parseFloat(ClearQuilo(data.total_quantity.toString()))
    if (data.destiny_weight != undefined) data.destiny_weight = parseFloat(ClearQuilo(data.destiny_weight.toString()))
    if (data.freight_cost_tonne != undefined) data.freight_cost_tonne = parseFloat(ClearMoney(data.freight_cost_tonne.toString()))
    if (data.balance != undefined) data.balance = parseFloat(ClearMoney(data.balance.toString()))
    if (data.credit != undefined) data.credit = parseFloat(ClearMoney(data.credit.toString()))
    if(isNaN(data.impurity_discount!)) data.impurity_discount = undefined
    if(isNaN(data.moisture_discont!)) data.moisture_discont = undefined

    data.costs = data.costs?.map(item => {
      item.amount = parseFloat(ClearMoney(item.amount.toString()))      
      return item
    })

    data.transport_contracts = data.transport_contracts?.map((item, key) => {
      item.total_weight = parseFloat(ClearQuilo(item.total_weight.toString()))      
      item.liquid_weight = parseFloat(ClearQuilo(item.liquid_weight.toString()))      
      item.moisture_discount = parseFloat(ClearQuilo(item.moisture_discount?.toString()?? ""))      
      item.impurity_discount = parseFloat(ClearQuilo(item.impurity_discount?.toString()?? ""))      
      item.outhers_discount = parseFloat(ClearQuilo(item.outhers_discount?.toString()?? "")) 
      return item
    })

    data.transport_invoices = data.transport_invoices?.map((item, key) => {
      item.total_amount = parseFloat(ClearMoney(item.total_amount.toString()))
      item.total_tax = parseFloat(ClearMoney(item.total_tax.toString()))
      item.rate_tax = parseFloat(ClearMoney(item.rate_tax.toString()))
      item.total_weight = parseFloat(ClearQuilo(item.total_weight.toString()))
      if (item.file_url != undefined)
        item.file_url = item.file_url.toLowerCase();
      return item
    })

    //Loop to get images
    data.transport_invoices?.map((_, index) => {
      if (values.transport_invoices[index].file_url_file != undefined && data.transport_invoices?.[index].file_url_file != undefined) {
        data.transport_invoices[index].file_url_file = values.transport_invoices[index].file_url_file
      }
    })

    if (isNaN(data.lost_quantity ?? 0)) {
      data.lost_quantity = undefined
    }

    const response = await updateTransport(data);
    Toast({ response, setError })

    if (response.httpStatus == 200 || response.httpStatus == 201) {
      navigate(parent != undefined ? "/transports/" + parent + "/" + parent_uuid : "/transports-general");
    }
  };

  return (
    <Stack w={"100%"} spacing={"0"}>

      {parent == "drivers" ?
        <DriversNavigator step={7} uuid={parent_uuid} /> :
        parent == "contracts-general" ?
          <ContractsNavigator step={4} uuid={parent_uuid} /> :
          <TransportsNavigator step={1} uuid={uuid} />
      }

      <Flex w={"100%"} maxWidth={1480} mx={"auto"} overflow={"auto"}>
        <DefaultBox as={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
          <ContainerHeader title={"Editar Carregamento "} />
          <Form navigate={navigate} useFormReturn={useFormReturn} transportData={transportData} />
        </DefaultBox>
      </Flex>
    </Stack>
  );
}
