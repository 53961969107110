import { useEffect, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Box, SimpleGrid, Stack } from "@chakra-ui/react";
import { Input } from "../../../components/Form/Input";
import { NavigateFunction, useParams } from "react-router-dom";
import { TransportDataToCreateModel, TransportModel } from "../../../models/TransportModel";
import { FormActions } from "../../../components/Form/FormActions";
import { ClearMoney, ClearQuilo, FormateDate, FormateMoney, FormateMoneyFloat, FormateQuilo, HandleMaskMoney, HandleMaskQuilo } from "../../../utils/utils";
import { showTransportDataToCreate } from "../../../services/transports/showTransportDataToCreate";
import { OptionsProps, Select2 } from "../../../components/Form/Select";
import { indexVehicle } from "../../../services/vehicles/indexVehicle";
import InputMask from "react-input-mask";
import { TransportsCosts } from "./TransportsCosts";
import { TransportsInvoices } from "./TransportsInvoices";
import { TransportsContracts } from "./TransportsContracts";

interface FormProps {
    navigate: NavigateFunction
    useFormReturn: UseFormReturn<FieldValues, any>
    transportData?: TransportModel
}

export function Form({ navigate, useFormReturn, transportData }: FormProps) {
    const { parent_uuid, parent } = useParams();

    const { register, formState, setValue, getValues } = useFormReturn
    const { errors } = formState;

    const [transportDataToCreate, setTransportDataToCreate] = useState<TransportDataToCreateModel>();
    const getTransportDataToCreate = async () => {
        const dataToCreate = await showTransportDataToCreate();
        setTransportDataToCreate(dataToCreate)
    }

    const [OptionsVehicles, setOptionsVehicles] = useState<OptionsProps[]>([{ label: "Selecione o motorista", value: "" }]);
    const getVehiclesByDriver = async (driverUUID: string) => {
        const vehicleResponse = await indexVehicle({ driverUUID: driverUUID });
        let vehiclesToPush: OptionsProps[] = []
        if (vehicleResponse.vehicles.length > 0) {
            for (let index = 0; index < vehicleResponse.vehicles.length; index++) {
                if (vehicleResponse.vehicles[index].tractor_uuid == undefined) {
                    vehiclesToPush.push({ label: vehicleResponse.vehicles[index].plate, value: vehicleResponse.vehicles[index].uuid })
                }
            }
        }

        if (vehiclesToPush.length > 0) {
            setOptionsVehicles(vehiclesToPush);
        } else {
            setOptionsVehicles([{ label: "Nenhum trator encontrado", value: "" }])
        }
    }

    const [OptionsProducts, setOptionsProducts] = useState<OptionsProps[]>([]);
    const [OptionsDrivers, setOptionsDrivers] = useState<OptionsProps[]>([]);
    const [OptionsCompanies, setOptionsCompanies] = useState<OptionsProps[]>([]);
    const [OptionsContracts, setOptionsContracts] = useState<OptionsProps[]>([]);

    useEffect(() => {
        getTransportDataToCreate();
        if (parent == "drivers" && parent_uuid != undefined) {
            getVehiclesByDriver(parent_uuid)
        }
        if (transportData != undefined && transportData.driver_uuid) {
            getVehiclesByDriver(transportData.driver_uuid)
        }

        if (transportData?.transport_date != undefined) {
            transportData.transport_date = FormateDate(transportData.transport_date!, "DD/MM/YYYY")
        }
    }, [transportData])

    useEffect(() => {
        if (transportDataToCreate?.products != undefined) {
            let productsData: OptionsProps[] = []
            let driversData: OptionsProps[] = []
            let companiesData: OptionsProps[] = []
            let contractsData: OptionsProps[] = []

            for (let index = 0; index < transportDataToCreate?.products.length; index++) {
                const element = transportDataToCreate?.products[index];
                productsData.push({ label: element.name, value: element.uuid })
            }

            if (transportDataToCreate?.drivers != undefined) {
                for (let index = 0; index < transportDataToCreate?.drivers.length; index++) {
                    const element = transportDataToCreate?.drivers[index];
                    driversData.push({ label: element.name, value: element.uuid })
                }
            }

            if (transportDataToCreate?.companies != undefined) {
                for (let index = 0; index < transportDataToCreate?.companies.length; index++) {
                    const element = transportDataToCreate?.companies[index];
                    companiesData.push({ label: element.name, value: element.uuid })
                }
            }

            if (transportDataToCreate?.contracts != undefined) {
                for (let index = 0; index < transportDataToCreate?.contracts.length; index++) {
                    const element = transportDataToCreate?.contracts[index];
                    contractsData.push({ label: element.name, value: element.uuid })
                }
            }
            setOptionsProducts(productsData);
            setOptionsCompanies(companiesData);
            setOptionsDrivers(driversData);
            setOptionsContracts(contractsData);
            setValue("product_uuid", "");
            setValue("company_uuid", "");
            setValue("driver_uuid", "");
        }
    }, [transportDataToCreate, transportData])

    useEffect(() => {
        if (transportData) {
            if (transportData?.transport_date != undefined) {
                setValue("transport_date", transportData?.transport_date);
            }
            setValue("origin", transportData?.origin);
            setValue("destiny", transportData?.destiny);
            setValue("product_uuid", transportData?.product_uuid);
            setValue("vehicle_uuid", transportData?.vehicle_uuid);
            setValue("driver_uuid", transportData?.driver_uuid);
            setValue("moisture", transportData?.moisture);
            setValue("impurity", transportData?.impurity);
            setValue("impurity_discount", transportData?.impurity_discount);
            if (transportData?.freight_cost != undefined) setValue("freight_cost", FormateMoneyFloat(transportData?.freight_cost?.toString() ?? ""));
            if (transportData?.total_quantity != undefined) setValue("total_quantity", FormateQuilo(transportData?.total_quantity?.toString() ?? ""));
            if (transportData?.lost_quantity != undefined) setValue("lost_quantity", FormateQuilo(transportData?.lost_quantity?.toString() ?? ""));
            if (transportData?.moisture_discont != undefined) setValue("moisture_discont", FormateQuilo(transportData?.moisture_discont?.toString() ?? ""));
            if (transportData?.impurity_discount != undefined) setValue("impurity_discount", FormateQuilo(transportData?.impurity_discount?.toString() ?? ""));
            if (transportData?.lost_cost != undefined) setValue("lost_cost", FormateMoneyFloat(transportData?.lost_cost?.toString() ?? ""));
            if (transportData?.payment != undefined) setValue("payment", FormateMoneyFloat(transportData?.payment?.toString() ?? ""));
            if (transportData?.destiny_weight != undefined) setValue("destiny_weight", FormateQuilo(transportData?.destiny_weight?.toString() ?? ""));
            if (transportData?.freight_cost_tonne != undefined) setValue("freight_cost_tonne", FormateMoneyFloat(transportData?.freight_cost_tonne?.toString() ?? ""));
            if (transportData?.balance != undefined) setValue("balance", FormateMoneyFloat(transportData?.balance?.toString() ?? ""));
            if (transportData?.credit != undefined) setValue("credit", FormateMoneyFloat(transportData?.credit?.toString() ?? ""));
        }
    }, [OptionsDrivers, OptionsProducts, OptionsCompanies, OptionsVehicles, transportData])

    const handleFreightCost = () => {
        const total_quantity = parseFloat(ClearQuilo(getValues(`total_quantity`)))
        const freight_cost = parseFloat(ClearMoney(getValues(`freight_cost`)))

        if (freight_cost > 0 && total_quantity > 0) {
            const freight_cost_tonne = (freight_cost / total_quantity) * 1000
            if (freight_cost_tonne > 0) {
                setValue(`freight_cost_tonne`, FormateMoneyFloat(freight_cost_tonne.toFixed(2).toString()))
                return
            }
        }
        setValue(`freight_cost_tonne`, "")
    }

    const handleBalance = () => {
        const freight_cost = parseFloat(ClearMoney(getValues(`freight_cost`)))
        const lost_cost = parseFloat(ClearMoney(getValues(`lost_cost`)))
        const payment = parseFloat(ClearMoney(getValues(`payment`)))
        const credit = parseFloat(ClearMoney(getValues(`credit`)))

        if (freight_cost > 0) {
            const balance = freight_cost - lost_cost - payment + credit
            if (balance > 0) {
                setValue(`balance`, FormateMoneyFloat(balance.toString()))
                return
            }
        }
        setValue(`balance`, "")
    }

    const handleLost = () => {
        const lost_quantity = parseFloat(ClearQuilo(getValues(`lost_quantity`)))
        const total_quantity = parseFloat(ClearQuilo(getValues(`total_quantity`)))

        if (total_quantity > 0 && lost_quantity > 0 && lost_quantity < total_quantity) {
            const destiny_weight = total_quantity - lost_quantity
            if (destiny_weight > 0) {
                setValue(`destiny_weight`, FormateQuilo(destiny_weight.toString()))
                return
            }
        }
        setValue(`destiny_weight`, "")
    }

    return (
        <>

            <Stack direction={"column"} spacing={"4"}>
                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        as={InputMask}
                        mask={"99/99/9999"}
                        maskChar={''}
                        label="Data do Carregamento"
                        defaultValue={transportData?.transport_date}
                        error={errors.transport_date}
                        {...register("transport_date")}
                    />
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                </SimpleGrid>
                <TransportsContracts useFormReturn={useFormReturn} transportContracts={transportData?.transport_contracts} optionsContracts={OptionsContracts} />
                <TransportsInvoices useFormReturn={useFormReturn} transportInvoices={transportData?.transport_invoices} optionsCompanies={OptionsCompanies} />

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Select2
                        label="Motorista"
                        options={(OptionsDrivers.length > 0) ? OptionsDrivers : [{ label: "Carregando...", value: "-" }]}
                        error={errors.driver_uuid}
                        useFormReturn={useFormReturn}
                        name={"driver_uuid"}
                        selectedValue={transportData?.driver_uuid}
                        onChangeSelect={(driverUUID) => { getVehiclesByDriver(driverUUID) }}
                    />

                    <Select2
                        label="Placa/Trator"
                        options={(OptionsVehicles.length > 0) ? OptionsVehicles : [{ label: "Carregando...", value: "-" }]}
                        error={errors.vehicle_uuid}
                        useFormReturn={useFormReturn}
                        selectedValue={transportData?.vehicle_uuid}
                        name={"vehicle_uuid"}
                    />
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Select2
                        label="Produto"
                        options={(OptionsProducts.length > 0) ? OptionsProducts : [{ label: "Carregando...", value: "-" }]}
                        error={errors.product_uuid}
                        useFormReturn={useFormReturn}
                        name={"product_uuid"}
                        selectedValue={transportData?.product_uuid}
                    />

                    <Input
                        label="Cidade Origem"
                        error={errors.origin}
                        {...register("origin")}
                    />
                    <Input
                        label="Cidade Destino"
                        error={errors.destiny}
                        {...register("destiny")}
                    />
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>

                    <Input
                        label="Peso Origem Kg."
                        error={errors.total_quantity}
                        onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                            HandleMaskQuilo(e)
                            if (parseFloat(ClearQuilo(e.currentTarget.value)) > 0) {
                                setValue(`total_quantity`, e.currentTarget.value)
                                handleFreightCost()
                                handleLost()
                            }
                        }}
                        {...register("total_quantity")}
                    />

                    <Input
                        label="Quebra Kg."
                        error={errors.lost_quantity}
                        onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                            HandleMaskQuilo(e)
                            if (parseFloat(ClearQuilo(e.currentTarget.value)) > 0) {
                                setValue(`lost_quantity`, e.currentTarget.value)
                                handleLost()
                            }
                        }}
                        {...register("lost_quantity")}
                    />

<Input
                        label="Peso Destino Kg."
                        error={errors.destiny_weight}
                        isDisabled={true}                        
                        {...register("destiny_weight")}
                    />

                    <Input
                        label="Valor do frete"
                        error={errors.freight_cost}
                        onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                            HandleMaskMoney(e)
                            if (parseFloat(ClearMoney(e.currentTarget.value)) > 0) {
                                setValue(`freight_cost`, e.currentTarget.value)
                                handleFreightCost()
                                handleBalance()
                            }
                        }}
                        {...register("freight_cost")}
                    />
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Input
                        label="Valor por Tol."
                        error={errors.freight_cost_tonne}
                        isDisabled={true}
                        {...register("freight_cost_tonne")}
                    />
                    <Input
                        label="Desconto R$"
                        error={errors.lost_cost}
                        onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                            HandleMaskMoney(e)
                            if (parseFloat(ClearMoney(e.currentTarget.value)) > 0) {
                                setValue(`lost_cost`, e.currentTarget.value)
                                handleBalance()
                            }
                        }}
                        {...register("lost_cost")}
                    />

                    <Input
                        label="Adiantamento"
                        error={errors.payment}
                        onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                            HandleMaskMoney(e)
                            if (parseFloat(ClearMoney(e.currentTarget.value)) > 0) {
                                setValue(`payment`, e.currentTarget.value)
                                handleBalance()
                            }
                        }}
                        {...register("payment")}
                    />

                    <Input
                        label="Creditos R$"
                        error={errors.credit}
                        onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                            HandleMaskMoney(e)
                            if (parseFloat(ClearMoney(e.currentTarget.value)) > 0) {
                                setValue(`credit`, e.currentTarget.value)
                                handleBalance()
                            }
                        }}
                        {...register("credit")}
                    />
                </SimpleGrid>

                <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                    <Box></Box>
                    <Input
                        label="Saldo Frete R$"
                        error={errors.balance}
                        isDisabled={true}
                        {...register("balance")}
                    />
                </SimpleGrid>

                <TransportsCosts useFormReturn={useFormReturn} transportCosts={transportData?.costs} />

            </Stack>

            <FormActions navigate={navigate} useFormReturn={useFormReturn} />
        </>
    )
}