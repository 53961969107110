import { Table, Thead, Tr, Th, Tbody, Td, IconButton, Icon, Text, Box, SimpleGrid, Stack } from "@chakra-ui/react";
import { RiPencilLine, RiDeleteBin5Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ConfirmDeleteAlert } from "../../../components/ConfirmDeleteAlert/ConfirmDeleteAlert";
import { useConfirmDelete } from "../../../contexts/ConfirmDeleteContext";
import { FormateDate, FormateQuilo, Toast } from "../../../utils/utils";
import { TransportContractModel } from "../../../models/TransportContractModel";
import { deleteTransportContract } from "../../../services/transportContracts/deleteTransportContract";
import { formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";

interface ListTableProps {
  transportContracts?: TransportContractModel[];
  parent?: string;
  parent_uuid?: string;
  refetch?: () => void;
}

export function ListTable({ transportContracts, parent, parent_uuid, refetch }: ListTableProps) {
  const { onOpen, onClose, uuidToDelete } = useConfirmDelete()
  const handleDelete = async () => {
    onClose();
    const response = await deleteTransportContract(uuidToDelete);
    Toast({ response })
    if ((response.httpStatus == 200 || response.httpStatus == 201) && refetch) {
      refetch();
    }
  }
  return (
    <>
      <ConfirmDeleteAlert onDelete={handleDelete} />
      <Table colorScheme="gray">
        <Thead>
          <Tr>
          <Th pl={"0"}>Dados do transporte</Th>            
          <Th minWidth={120}></Th>
          </Tr>
        </Thead>
        <Tbody>

        {transportContracts?.map((transportContract) => {
            return (
              <Tr key={transportContract.uuid}>
                <Td pl={"0"}>
                  <Stack direction={"row"} spacing={"4"}>
                    <SimpleGrid spacing={"4"} w={"100%"}>
                      <Box>
                        <Text fontSize="sm"><b>Origem:</b> {(transportContract.transport_origin.toUpperCase())}</Text>
                        <Text fontSize="sm"><b>Destino:</b> {(transportContract.transport_destiny.toUpperCase())}</Text>
                        <Text fontSize="sm"><b>Produto:</b> {(transportContract.product_name.toUpperCase())}</Text>
                        {/* <Text fontSize="sm"><b>Identificador:</b> {transport.number.toUpperCase()}</Text> */}
                      </Box>
                    </SimpleGrid>

                    <SimpleGrid spacing={"4"} w={"100%"}>
                      <Box>
                        <Text fontSize="sm"><b>Placa:</b> {(transportContract.vehicle_plate.toUpperCase())}</Text>
                        <Text fontSize="sm"><b>Motorista:</b> {(transportContract.driver_name.toUpperCase())}</Text>
                        {/* <Text fontSize="sm" title={formatDistanceToNow(new Date(transport.created_at!), { addSuffix: true, locale: ptBR })}><b>DT Cadastro:</b> {FormateDate(transport.created_at!, "DD/MM/YYYY")}</Text> */}
                        <Text fontSize="sm"title={formatDistanceToNow(new Date(transportContract.transport_date!), { addSuffix: true, locale: ptBR })}><b>DT Carregamento:</b> {transportContract.transport_date ? FormateDate(transportContract.transport_date, "DD/MM/YYYY") : "-"}</Text>
                      </Box>
                    </SimpleGrid>                    
                  </Stack>
                </Td>
                <Td textAlign={"end"} pr={"0"}>
                  {transportContract.deleted_at != undefined ? (
                    <Text color="gray.300">DELETADO</Text>
                  ) : (
                    <>
                      <IconButton
                        mr={"4"}
                        as={Link}
                        to={parent != undefined ? "/transports/" + parent + "/" + parent_uuid + "/edit/" + transportContract.transport_uuid : "/transports-general" + "/edit/" + transportContract.transport_uuid}
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"orange"}
                        icon={<Icon as={RiPencilLine} />}
                        aria-label={"Editar"}
                      />
                      <IconButton
                        size={"xs"}
                        fontSize={"xs"}
                        colorScheme={"red"}
                        icon={<Icon as={RiDeleteBin5Line} />}
                        aria-label={"Deletar"}
                        onClick={() => onOpen(transportContract.uuid)}
                      />
                    </>
                  )}
                </Td>
              </Tr>
            );
          })}
          
          
        </Tbody>
      </Table >
    </>
  );
}
