import { SimpleGrid, Button, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface ContractsNavigatorProps {
    uuid?: string;
    step: number;
}

export function ContractsNavigator({ uuid, step }: ContractsNavigatorProps) {
    return (
        <Stack mb={"4"}>
            <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"} mb={"2"}>
                <Button as={Link} to={"/contracts-general/edit/" + uuid} colorScheme={step == 1 ? "teal" : "gray"} variant={step == 1 ? "solid" : "outline"}>1. Informações base</Button>
            </SimpleGrid>
            <SimpleGrid minChildWidth={"170px"} spacing={"4"} w={"100%"}>
                <Button as={Link} to={"/transactions/contracts-general/" + uuid} colorScheme={step == 2 ? "teal" : "gray"} variant={step == 2 ? "solid" : "outline"}>2. Financeiro</Button>
                <Button as={Link} to={"/files/contracts-general/" + uuid} colorScheme={step == 3 ? "teal" : "gray"} variant={step == 3 ? "solid" : "outline"}>3. Arquivos</Button>
                <Button as={Link} to={"/transports/contracts-general/" + uuid} colorScheme={step == 4 ? "teal" : "gray"} variant={step == 4 ? "solid" : "outline"}>4. Carregamentos</Button>
                <Button as={Link} to={"/storages/contracts-general/" + uuid} colorScheme={step == 5 ? "teal" : "gray"} variant={step == 5 ? "solid" : "outline"}>5. Armazenamento</Button>
            </SimpleGrid>
        </Stack >
    )
}